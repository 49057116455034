<template>
  <b-row class="mt-2">
    <b-col
      lg="4"
      md="6"
    >
      <b-form-group
        label-for="tax-id"
        label="CPF/CNPJ"
      >
        <validation-provider
          #default="{ errors }"
          name="CPF/CNPJ"
          rules="tax_id|required"
        >
          <b-form-input
            id="tax-id"
            v-model="registration.contact.tax_id"
            v-mask="['###.###.###-##', '##.###.###/####-##']"
            placeholder="CPF/CNPJ"
            :state="errors.length > 0 ? false : null"
          />
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
      </b-form-group>
    </b-col>

    <b-col
      lg="4"
      md="6"
    >
      <validation-provider
        #default="{ errors }"
        name="Nome"
        rules="required"
      >
        <b-form-group
          label-for="name"
          label="Nome"
        >
          <b-form-input
            id="name"
            v-model="registration.contact.name"
            placeholder="Nome"
            :state="errors.length > 0 ? false : null"
          />
          <small class="text-danger">{{ errors[0] }}</small>
        </b-form-group>
      </validation-provider>
    </b-col>

    <b-col
      lg="4"
      md="6"
    >
      <validation-provider
        #default="{ errors }"
        name="Email"
        rules="email"
      >
        <b-form-group
          label-for="email"
          label="Email"
        >
          <b-form-input
            id="email"
            v-model="registration.contact.email"
            placeholder="E-mail"
            :state="errors.length > 0 ? false : null"
          />
          <small class="text-danger">{{ errors[0] }}</small>
        </b-form-group>
      </validation-provider>
    </b-col>

    <b-col
      lg="4"
      md="6"
    >
      <validation-provider
        #default="{ errors }"
        name="Telefone"
        rules="phone"
      >
        <b-form-group
          label-for="phone"
          label="Telefone"
        >
          <b-form-input
            id="phone"
            v-model="registration.contact.phone_number"
            v-mask="[
              '(##) ####-####',
              '(##) #####-####'
            ]"
            placeholder="Telefone"
            :state="errors.length > 0 ? false : null"
          />
          <small class="text-danger">{{ errors[0] }}</small>
        </b-form-group>
      </validation-provider>
    </b-col>

    <b-col
      lg="4"
      md="6"
    >
      <validation-provider
        #default="{ errors }"
        name="Celular"
        rules="phone"
      >
        <b-form-group
          label-for="phone"
          label="Celular"
        >
          <b-form-input
            id="phone"
            v-model="registration.contact.cell_phone_number"
            v-mask="[
              '(##) ####-####',
              '(##) #####-####'
            ]"
            placeholder="Celular"
            :state="errors.length > 0 ? false : null"
          />
          <small class="text-danger">{{ errors[0] }}</small>
        </b-form-group>
      </validation-provider>
    </b-col>

  </b-row>
</template>

<script>
import {
    BCol,
    BRow,
    BFormGroup,
    BFormSelect,
    BFormInput,
} from 'bootstrap-vue';

import {
    ValidationProvider,
} from 'vee-validate';

export default {
    components: {
        BCol,
        BRow,
        BFormGroup,
        BFormSelect,
        ValidationProvider,
        BFormInput,
    },

    props: {
        registration: {
            type: Object,
            required: true,
        },
    },

};
</script>
