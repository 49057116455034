var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-row',{staticClass:"mt-2"},[_c('b-col',{attrs:{"lg":"4","md":"6"}},[_c('b-form-group',{attrs:{"label-for":"tax-id","label":"CPF/CNPJ"}},[_c('validation-provider',{attrs:{"name":"CPF/CNPJ","rules":"tax_id|required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{directives:[{name:"mask",rawName:"v-mask",value:(['###.###.###-##', '##.###.###/####-##']),expression:"['###.###.###-##', '##.###.###/####-##']"}],attrs:{"id":"tax-id","placeholder":"CPF/CNPJ","state":errors.length > 0 ? false : null},model:{value:(_vm.registration.contact.tax_id),callback:function ($$v) {_vm.$set(_vm.registration.contact, "tax_id", $$v)},expression:"registration.contact.tax_id"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"lg":"4","md":"6"}},[_c('validation-provider',{attrs:{"name":"Nome","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label-for":"name","label":"Nome"}},[_c('b-form-input',{attrs:{"id":"name","placeholder":"Nome","state":errors.length > 0 ? false : null},model:{value:(_vm.registration.contact.name),callback:function ($$v) {_vm.$set(_vm.registration.contact, "name", $$v)},expression:"registration.contact.name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1),_c('b-col',{attrs:{"lg":"4","md":"6"}},[_c('validation-provider',{attrs:{"name":"Email","rules":"email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label-for":"email","label":"Email"}},[_c('b-form-input',{attrs:{"id":"email","placeholder":"E-mail","state":errors.length > 0 ? false : null},model:{value:(_vm.registration.contact.email),callback:function ($$v) {_vm.$set(_vm.registration.contact, "email", $$v)},expression:"registration.contact.email"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1),_c('b-col',{attrs:{"lg":"4","md":"6"}},[_c('validation-provider',{attrs:{"name":"Telefone","rules":"phone"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label-for":"phone","label":"Telefone"}},[_c('b-form-input',{directives:[{name:"mask",rawName:"v-mask",value:([
            '(##) ####-####',
            '(##) #####-####'
          ]),expression:"[\n            '(##) ####-####',\n            '(##) #####-####'\n          ]"}],attrs:{"id":"phone","placeholder":"Telefone","state":errors.length > 0 ? false : null},model:{value:(_vm.registration.contact.phone_number),callback:function ($$v) {_vm.$set(_vm.registration.contact, "phone_number", $$v)},expression:"registration.contact.phone_number"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1),_c('b-col',{attrs:{"lg":"4","md":"6"}},[_c('validation-provider',{attrs:{"name":"Celular","rules":"phone"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label-for":"phone","label":"Celular"}},[_c('b-form-input',{directives:[{name:"mask",rawName:"v-mask",value:([
            '(##) ####-####',
            '(##) #####-####'
          ]),expression:"[\n            '(##) ####-####',\n            '(##) #####-####'\n          ]"}],attrs:{"id":"phone","placeholder":"Celular","state":errors.length > 0 ? false : null},model:{value:(_vm.registration.contact.cell_phone_number),callback:function ($$v) {_vm.$set(_vm.registration.contact, "cell_phone_number", $$v)},expression:"registration.contact.cell_phone_number"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }