<template>
  <b-row>
    <b-col md="4">
      <b-form-group
        label-for="comercial-agent-description"
        label="Descrição do Representante"
      >
        <BFormTextarea
          id="comercial-agent-description"
          v-model="registration.comercial_agent_description"
          placeholder="Descrição do Representante"
        />
      </b-form-group>
    </b-col>
  </b-row>
</template>

<script>
import {
    BFormTextarea, BFormGroup, BRow, BCol,
} from 'bootstrap-vue';

export default {
    components: {
        BFormTextarea,
        BFormGroup,
        BRow,
        BCol,
    },
    props: {
        registration: {
            type: Object,
            required: true,
        },
    },
};
</script>
