import Ramses from '../base/ramses';

class SalesDevelopmentRepresentativeService extends Ramses {
    index() {
        return this.fetch({
            url: 'sales-development-representative',
        });
    }
}

export default new SalesDevelopmentRepresentativeService();
