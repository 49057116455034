import Ramses from '../base/ramses';

class PostalCodeService extends Ramses {
    getPostalCode(postalCode) {
        const postalCodeWithoutSymbols = postalCode.replace(/-/g, '');
        return this.fetch({
            url: `/ws/postal-code/${postalCodeWithoutSymbols}`,
        });
    }
}

export default new PostalCodeService();
