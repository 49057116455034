<template>
  <b-row class="mt-2">
    <b-col
      lg="4"
      md="6"
    >
      <b-form-group
        label-for="tax-id"
        label="CPF/CNPJ"
      >
        <validation-provider
          #default="{ errors }"
          name="CPF/CNPJ"
          :rules="readonly ? '' : `required|tax_id|already-used:${allDocumentsAlreadyInUse}`"
        >
          <b-form-input
            id="tax-id"
            v-model="registration.document"
            v-mask="['###.###.###-##', '##.###.###/####-##']"
            :disabled="readonly"
            placeholder="CPF/CNPJ"
            :state="errors.length > 0 ? false : null"
          />
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
      </b-form-group>
    </b-col>

    <b-col
      lg="4"
      md="6"
    >
      <validation-provider
        #default="{ errors }"
        name="Razão Social"
        rules="required"
      >
        <b-form-group
          label-for="name"
          label="Razão Social"
        >
          <b-form-input
            id="name"
            v-model="registration.name"
            placeholder="Razão Social"
            :state="errors.length > 0 ? false : null"
          />
          <small class="text-danger">{{ errors[0] }}</small>
        </b-form-group>
      </validation-provider>
    </b-col>

    <!-- <b-col
              lg="4"
              md="6"
            >
              <validation-provider
                #default="{ errors }"
                name="Razão Social"
                rules="required"
              >
                <b-form-group
                  label-for="legal-tax-name"
                  label="Razão Social"
                >
                  <b-form-input
                    id="legal-tax-name"
                    v-model="registration.company_name"
                    placeholder="Razão Social"
                    :state="errors.length > 0 ? false : null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col> -->

    <b-col
      lg="4"
      md="6"
    >
      <validation-provider
        #default="{ errors }"
        name="Email"
        :rules="readonly ? '' : `required|email|already-used:${allEmailsAlreadyUsed}`"
      >
        <b-form-group
          label-for="email"
          label="Email"
        >
          <b-form-input
            id="email"
            v-model="registration.email"
            :disabled="readonly"
            placeholder="E-mail"
            :state="errors.length > 0 ? false : null"
          />
          <small class="text-danger">{{ errors[0] }}</small>
        </b-form-group>
      </validation-provider>
    </b-col>

    <b-col
      lg="4"
      md="6"
    >
      <validation-provider
        #default="{ errors }"
        name="Telefone"
        rules="required"
      >
        <b-form-group
          label-for="phone"
          label="Telefone"
        >
          <b-form-input
            id="phone"
            v-model="registration.phone"
            v-mask="[
              '(##) ####-####',
              '(##) #####-####'
            ]"
            placeholder="Telefone"
            :state="errors.length > 0 ? false : null"
          />
          <small class="text-danger">{{ errors[0] }}</small>
        </b-form-group>
      </validation-provider>
    </b-col>
    <b-col
      lg="4"
      md="6"
    >
      <b-form-group
        label-for="company_type"
        label="Ramo de Atividade"
      >
        <validation-provider
          #default="{ errors }"
          name="Ramo de Atividade"
          rules="required"
        >
          <b-form-select
            id="company_type"
            v-model="registration.company_type"
            name="login-email"
            :state="errors.length > 0 ? false : null"
            :options="companyTypesOptions"
            class="input-color"
          />
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
      </b-form-group>
    </b-col>

    <b-col
      lg="4"
      md="6"
    >
      <validation-provider
        #default="{ errors }"
        name="Faturamento Médio Mensal"
        rules="required"
      >
        <b-form-group
          label-for="monthly-income"
          label="Faturamento Médio Mensal"
        >
          <b-form-input
            id="monthly-income"
            v-model="registration.monthly_income"
            v-money="money"
            placeholder="Faturamento Médio Mensal"
            :state="errors.length > 0 ? false : null"
          />

          <small class="text-danger">{{ errors[0] }}</small>
        </b-form-group>
      </validation-provider>
    </b-col>

    <b-col
      lg="4"
      md="6"
    >
      <validation-provider
        #default="{ errors }"
        name="Agente Comercial"
        rules=""
      >
        <b-form-group
          label-for="comercial-agent"
          label="Agente Comercial"
        >
          <b-form-select
            id="comercial-agent"
            v-model="registration.comercial_agent_id"
            placeholder="Agente Comercial"
            :options="comercialAgentsOptions"
            :state="errors.length > 0 ? false : null"
          />

          <small class="text-danger">{{ errors[0] }}</small>
        </b-form-group>
      </validation-provider>
    </b-col>

    <b-col
      lg="4"
      md="6"
    >
      <validation-provider
        #default="{ errors }"
        name="Pré Vendedor"
      >
        <b-form-group
          label-for="comercial-agent"
          label="Pré Vendedor"
        >
          <b-form-select
            id="comercial-agent"
            v-model="registration.sales_development_representative_id"
            placeholder="Pré Vendedor"
            :options="salesDevelopmentRepresentativeOptions"
            :state="errors.length > 0 ? false : null"
          />

          <small class="text-danger">{{ errors[0] }}</small>
        </b-form-group>
      </validation-provider>
    </b-col>

  </b-row>
</template>

<script>
import {
    BCol,
    BRow,
    BFormGroup,
    BFormSelect,
    BFormInput,
} from 'bootstrap-vue';

import {
    ValidationProvider,
} from 'vee-validate';

import ComercialAgentsService from '@/service/comercial-agents';
import SalesDevelopmentRepresentative from '@/service/sales-development-representative';

import companyTypes from '@/informations/company_types';

export default {
    components: {
        BCol,
        BRow,
        BFormGroup,
        BFormSelect,
        ValidationProvider,
        BFormInput,
    },

    props: {
        registration: {
            type: Object,
            required: true,
        },

        allRegistrations: {
            type: Array,
            default: () => [],
        },

        readonly: {
            type: Boolean,
            default: () => false,
        },
    },

    data() {
        return {
            comercialAgents: [],
            salesDevelopmentRepresentative: [],
            companyTypesOptions: [
                { text: 'Selecione um Ramo de Atividade', value: null },
                ...companyTypes,
            ],
            money: {
                decimal: ',',
                thousands: '.',
                prefix: 'R$ ',
                precision: 2,
                masked: false,
            },
        };
    },
    computed: {
        allEmailsAlreadyUsed() {
            return this.allRegistrations.map(item => item.email);
        },

        allDocumentsAlreadyInUse() {
            return this.allRegistrations.map(({ document }) => {
                if (document.length === 11) {
                    return document.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/g, '\$1.\$2.\$3\-\$4');
                }

                return document.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g, '\$1.\$2.\$3\/\$4\-\$5');
            });
        },

        comercialAgentsOptions() {
            const options = this.comercialAgents.filter(item => item.is_active).map(item => ({
                value: item.id,
                text: item.name,
            })).sort((a, b) => a.text.localeCompare(b.text));

            options.unshift({
                value: null,
                text: 'Selecione um Agente Comercial',
            });
            return options;
        },

        salesDevelopmentRepresentativeOptions() {
            const options = (this.salesDevelopmentRepresentative).map(item => ({
                text: item.name,
                value: item.id,
            }));

            options.unshift({
                value: null,
                text: 'Selecione um Pré Vendedor',
            });

            return options;
        },
    },
    async mounted() {
        await this.getComercialAgents();
        await this.getSalesDevelopmentRepresentative();

        const comericalAgentHasMatched = this.comercialAgents.find(item => item.user_id === this.$store.state.user.data.id);

        if (comericalAgentHasMatched) {
            this.registration.comercial_agent_id = comericalAgentHasMatched.id;
        }
    },
    methods: {
        async getComercialAgents() {
            const { data: { data } } = await ComercialAgentsService.getAllAgents();
            this.comercialAgents = data;
        },

        async getSalesDevelopmentRepresentative() {
            const { data: { data } } = await SalesDevelopmentRepresentative.index();
            this.salesDevelopmentRepresentative = data;
        },
    },
};
</script>
