var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-row',{staticClass:"mt-2"},[_c('b-col',{attrs:{"lg":"4","md":"6"}},[_c('b-form-group',{attrs:{"label-for":"tax-id","label":"CPF/CNPJ"}},[_c('validation-provider',{attrs:{"name":"CPF/CNPJ","rules":_vm.readonly ? '' : ("required|tax_id|already-used:" + _vm.allDocumentsAlreadyInUse)},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{directives:[{name:"mask",rawName:"v-mask",value:(['###.###.###-##', '##.###.###/####-##']),expression:"['###.###.###-##', '##.###.###/####-##']"}],attrs:{"id":"tax-id","disabled":_vm.readonly,"placeholder":"CPF/CNPJ","state":errors.length > 0 ? false : null},model:{value:(_vm.registration.document),callback:function ($$v) {_vm.$set(_vm.registration, "document", $$v)},expression:"registration.document"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"lg":"4","md":"6"}},[_c('validation-provider',{attrs:{"name":"Razão Social","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label-for":"name","label":"Razão Social"}},[_c('b-form-input',{attrs:{"id":"name","placeholder":"Razão Social","state":errors.length > 0 ? false : null},model:{value:(_vm.registration.name),callback:function ($$v) {_vm.$set(_vm.registration, "name", $$v)},expression:"registration.name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1),_c('b-col',{attrs:{"lg":"4","md":"6"}},[_c('validation-provider',{attrs:{"name":"Email","rules":_vm.readonly ? '' : ("required|email|already-used:" + _vm.allEmailsAlreadyUsed)},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label-for":"email","label":"Email"}},[_c('b-form-input',{attrs:{"id":"email","disabled":_vm.readonly,"placeholder":"E-mail","state":errors.length > 0 ? false : null},model:{value:(_vm.registration.email),callback:function ($$v) {_vm.$set(_vm.registration, "email", $$v)},expression:"registration.email"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1),_c('b-col',{attrs:{"lg":"4","md":"6"}},[_c('validation-provider',{attrs:{"name":"Telefone","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label-for":"phone","label":"Telefone"}},[_c('b-form-input',{directives:[{name:"mask",rawName:"v-mask",value:([
            '(##) ####-####',
            '(##) #####-####'
          ]),expression:"[\n            '(##) ####-####',\n            '(##) #####-####'\n          ]"}],attrs:{"id":"phone","placeholder":"Telefone","state":errors.length > 0 ? false : null},model:{value:(_vm.registration.phone),callback:function ($$v) {_vm.$set(_vm.registration, "phone", $$v)},expression:"registration.phone"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1),_c('b-col',{attrs:{"lg":"4","md":"6"}},[_c('b-form-group',{attrs:{"label-for":"company_type","label":"Ramo de Atividade"}},[_c('validation-provider',{attrs:{"name":"Ramo de Atividade","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var errors = ref.errors;
return [_c('b-form-select',{staticClass:"input-color",attrs:{"id":"company_type","name":"login-email","state":errors.length > 0 ? false : null,"options":_vm.companyTypesOptions},model:{value:(_vm.registration.company_type),callback:function ($$v) {_vm.$set(_vm.registration, "company_type", $$v)},expression:"registration.company_type"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"lg":"4","md":"6"}},[_c('validation-provider',{attrs:{"name":"Faturamento Médio Mensal","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label-for":"monthly-income","label":"Faturamento Médio Mensal"}},[_c('b-form-input',{directives:[{name:"money",rawName:"v-money",value:(_vm.money),expression:"money"}],attrs:{"id":"monthly-income","placeholder":"Faturamento Médio Mensal","state":errors.length > 0 ? false : null},model:{value:(_vm.registration.monthly_income),callback:function ($$v) {_vm.$set(_vm.registration, "monthly_income", $$v)},expression:"registration.monthly_income"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1),_c('b-col',{attrs:{"lg":"4","md":"6"}},[_c('validation-provider',{attrs:{"name":"Agente Comercial","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label-for":"comercial-agent","label":"Agente Comercial"}},[_c('b-form-select',{attrs:{"id":"comercial-agent","placeholder":"Agente Comercial","options":_vm.comercialAgentsOptions,"state":errors.length > 0 ? false : null},model:{value:(_vm.registration.comercial_agent_id),callback:function ($$v) {_vm.$set(_vm.registration, "comercial_agent_id", $$v)},expression:"registration.comercial_agent_id"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1),_c('b-col',{attrs:{"lg":"4","md":"6"}},[_c('validation-provider',{attrs:{"name":"Pré Vendedor"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label-for":"comercial-agent","label":"Pré Vendedor"}},[_c('b-form-select',{attrs:{"id":"comercial-agent","placeholder":"Pré Vendedor","options":_vm.salesDevelopmentRepresentativeOptions,"state":errors.length > 0 ? false : null},model:{value:(_vm.registration.sales_development_representative_id),callback:function ($$v) {_vm.$set(_vm.registration, "sales_development_representative_id", $$v)},expression:"registration.sales_development_representative_id"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }