<template>
  <b-container>

    <b-card title="Cadastro Online">
      <validation-observer
        ref="onlineRegistration"
        #default="{ invalid }"
      >
        <b-form>
          <h4> Informações Cadastrais </h4>

          <informations-form
            :readonly="!!hash"
            :registration="registration"
            :all-registrations="allRegistrations"
          />

          <h4 class="my-2">
            Contato
          </h4>

          <contact-form :registration="registration" />

          <h4 class="my-2">
            Endereço
          </h4>

          <address-form :address="registration.address" />

          <h4 class="my-2">
            Lead
          </h4>

          <LeadForm
            :has-payment-product="hasPaymentProduct"
            :registration="registration"
            @import-rentability="(value) => pendingFiles = {...pendingFiles, 'Simulador de Rentabilidade': value}"
          />

          <ExtraForm :registration="registration" />

          <b-form-group
            label-for="sector"
            label="Possui sócios?"
          >
            <b-form-checkbox
              v-model="registration.has_partners"
              switch
              inline
            />
          </b-form-group>

          <b-form-group
            label-for="sector"
            label="Possui E-CPF?"
          >
            <b-form-checkbox
              v-model="registration.has_ecpf"
              switch
              inline
            />
          </b-form-group>

          <b-button
            class="d-block m-auto"
            variant="primary"
            :disabled="invalid"
            @click="confirmModal"
          >
            {{ hash ? 'Editar' : 'Cadastrar' }}
          </b-button>
        </b-form>
      </validation-observer>
    </b-card>
  </b-container>
</template>

<script>
import {
    BContainer,
    BCard,
    BFormGroup,
    BForm,
    BButton,
    BFormCheckbox,
} from 'bootstrap-vue';

import {
    ValidationObserver,
} from 'vee-validate';

import OnlineRegistrationService from '@/service/online_registration';
import getCnpj from '@/service/cnpj';

import '@validations';

import { callLoading } from '@/service/utilities/index';

import AddressForm from '@/components/Form/AddressForm.vue';
import InformationsForm from './CreateOrUpdate/components/Form/Informations.vue';
import LeadForm from './CreateOrUpdate/components/Form/Lead.vue';
import ContactForm from './CreateOrUpdate/components/Form/Contact.vue';
import ExtraForm from './CreateOrUpdate/components/Form/Extra.vue';

const removeSymbolsMoney = value => (typeof value === 'string'
    ? (value.replace(/[R$ .]/g, '').replace(',', '.'))
    : value);

export default {
    components: {
        AddressForm,
        LeadForm,
        BContainer,
        BCard,
        BFormGroup,
        BForm,
        BButton,
        InformationsForm,
        ValidationObserver,
        BFormCheckbox,
        ContactForm,
        ExtraForm,
    },

    data() {
        return {
            hash: this.$route.params.hash,
            registration: {
                has_partners: false,
                record_type: null,
                conversion_type: null,
                company_type: null,
                sales_development_representative_id: null,
                products: [],
                address: {},
                comercial_agent_id: null,
                contact: {},
                has_ecpf: false,
            },
            pendingFiles: {},
            allRegistrations: [],
        };
    },

    computed: {
        accountTypeOptions() {
            const options = {
                Crédito: [
                    { value: 'CEDENTE', text: 'Cedente' },
                ],

                'CONTA SOULPAY': [
                    { value: 'CONTA SOULPAY', text: 'Conta SoulPay' },
                ],

                Hibrido: [
                    { value: 'HIBRIDO', text: 'Hibrido' },
                ],

                Sacado: [
                    { value: 'SACADO', text: 'Sacado' },
                ],
            };

            return [
                { text: 'Selecione um Tipo de Conta', value: null },
                ...options[this.registration.record_type],
            ];
        },

        comercialAgentsOptions() {
            const options = this.comercialAgents.filter(item => item.is_active).map(item => ({
                value: item.id,
                text: item.name,
            })).sort((a, b) => a.text.localeCompare(b.text));

            options.unshift({
                value: null,
                text: 'Selecione um Agente Comercial',
            });
            return options;
        },

        hasPaymentProduct() {
            const productIds = this.registration.products.map(({ id }) => id);
            return productIds.includes(15) || productIds.includes(16);
        },
    },

    watch: {
        'registration.document': async function (document) {
            if(document.length !== 18) { return }
            if (this.hash) { return; }
            const { data } = await getCnpj(document.replace(/[^a-zA-Z0-9]/g, ''));
            if(data.data) { return }

            const {
                email,
                fantasia: companyName,
                nome: name,
                telefone: phone,
                cep: postalCode,
                uf,
                municipio: city,
                bairro: district,
                numero: number,
                logradouro: address,
            } = data;

            this.registration = {
                ...this.registration,
                email,
                company_name: companyName,
                name,
                phone,
                address: {
                    address,
                    postal_code: postalCode,
                    state: uf,
                    city,
                    district,
                    number,
                },
            };
        },
    },

    async created() {
        this.callLoading(true);
        this.allRegistrations = await this.getAllLinks();
        if (this.hash) {
            await this.findRegistrationByHash();
        }
        this.callLoading(false);
    },

    methods: {
        async findRegistrationByHash() {
            const { data, status } = await OnlineRegistrationService.findRegistrationByHash(this.hash);

            if (status !== 200) { this.error('Você não ter permissão para ver esse cadastro'); return; }

            this.registration = {
                ...data,
                // products: data.registration_products.map(item => this.products.find(item2 => item.product.id === item2.productId)),
                products: data.registration_products.map(item => item.product),
                record_type: data.record_type.type,
                has_partners: !!data.has_partners,
                has_ecpf: !!data.has_ecpf,
                monthly_income: data.monthly_income.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }),
            };
        },

        async createRegistrationOnline() {
            callLoading(true);
            const request = {
                ...this.registration,
                has_partners: !!this.registration.has_partners,
                company_name: this.registration.name,
                monthly_income: removeSymbolsMoney(this.registration.monthly_income),
                products: this.registration.products.map(product => product.id),
                document: this.registration.document.replace(/[^a-zA-Z0-9]/g, ''),
                contact: {
                    ...this.registration.contact,
                    tax_id: this.registration.contact.tax_id.replace(/[^a-zA-Z0-9]/g, ''),
                },
            };

            const { status, data } = await (this.hash ? OnlineRegistrationService.updateRegistration(this.hash, request) : OnlineRegistrationService.createLink(request));
            callLoading(false);
            if (status === 201 || status === 200) {
                if (this.hasPaymentProduct && this.pendingFiles) {
                    await OnlineRegistrationService.sendPendingFiles(this.pendingFiles, data.link_hash);
                }

                this.success(`Link ${this.hash ? 'editado ' : 'criado'} com sucesso`);

                if (!this.hash) {
                    this.registration = {
                        has_partners: false,
                        record_type: null,
                        conversion_type: null,
                        company_type: null,
                        sales_development_representative_id: null,
                        products: [],
                        address: {},
                        comercial_agent_id: null,
                        contact: {},
                        has_ecpf: false,
                    };
                }
                this.$nextTick(() => this.$refs.onlineRegistration.reset());
            } else {
                this.error(`Não foi possível ${this.hash ? 'editar ' : 'criar'} o link`);
            }
        },

        async getAllLinks() {
            const { data: { data } } = await OnlineRegistrationService.getAllRegistrations();

            return data;
        },

        confirmModal() {
            this.confirmAnAction(this.hash ? 'Deseja Realmente Editar o Link?' : 'Deseja Realmente Criar o Link?')
                .then(value => {
                    if (value) {
                        this.createRegistrationOnline();
                    }
                });
        },

        success(message) {
            this.modalSuccess(message);
        },

        error(errorMessage) {
            this.modalError(errorMessage);
        },
    },
};

</script>

<style lang="scss">

.multiselect__option--highlight {
    background: #2E2C6C !important;
}

.multiselect__tag {
    background: #2E2C6C !important;
}

.dark-layout {
    .multiselect__tags {
        background-color: #283046;
        border-color: #3b4253;
    }
}
</style>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
