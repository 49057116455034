<template>
  <b-row>
    <b-col
      sm="12"
      lg="3"
      md="12"
    >
      <b-form-group
        label="CEP"
        label-for="v-cep"
      >
        <validation-provider
          #default="{ errors }"
          name="CEP"
        >
          <b-form-input
            id="v-cep"
            v-model="address.postal_code"
            v-mask="'#####-###'"
            placeholder="CEP"
            :state="errors.length > 0 ? false : null"
            @change="searchAddressByPostalCode(address.postal_code)"
          />
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
      </b-form-group>
    </b-col>
    <b-col
      sm="12"
      lg="3"
      md="12"
    >
      <b-form-group
        label="Estado"
        label-for="v-state"
      >
        <b-form-input
          id="v-state"
          v-model="address.state"
          placeholder="Estado"
        />
      </b-form-group>
    </b-col>
    <b-col
      sm="12"
      lg="3"
      md="12"
    >
      <b-form-group
        label="Cidade"
        label-for="v-city"
      >
        <b-form-input
          id="v-city"
          v-model="address.city"
          placeholder="Cidade"
        />
      </b-form-group>
    </b-col>
    <b-col
      sm="12"
      md="12"
      lg="3"
      offset-xl="0"
    >
      <b-form-group
        label="Endereço"
        label-for="v-address"
      >
        <b-form-input
          id="v-address"
          v-model="address.address"
          placeholder="Endereço"
        />
      </b-form-group>
    </b-col>
    <b-col
      sm="12"
      md="12"
      lg="3"
      offset-xl="0"
    >
      <b-form-group
        label="Bairro"
        label-for="v-district"
      >
        <b-form-input
          id="v-district"
          v-model="address.district"

          placeholder="Bairro"
        />
      </b-form-group>
    </b-col>
    <b-col
      sm="12"
      md="6"
      lg="3"
      offset-xl="0"
    >
      <b-form-group
        label="Número"
        label-for="v-number"
      >
        <validation-provider
          #default="{ errors }"
          name="Numero"
          :rules="address.postal_code && 'required'"
        >
          <b-form-input
            id="v-number"
            v-model="address.number"
            type="number"
            placeholder="Número"
            :state="errors.length > 0 ? false : null"
          />
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
      </b-form-group>
    </b-col>
    <b-col
      sm="12"
      md="6"
      lg="3"
    >
      <b-form-group
        label="Complemento"
        label-for="v-complemento"
      >
        <b-input-group class="input-group-merge">
          <b-form-input
            id="v-complemento"
            v-model="address.complement"
            placeholder="Complemento"
          />
        </b-input-group>
      </b-form-group>
    </b-col>
  </b-row>
</template>

<script>
import {
    BFormGroup, BFormInput, BRow, BCol, BInputGroup,
} from 'bootstrap-vue';

import {
    ValidationProvider,
} from 'vee-validate';

import PostalCodeService from '@/service/postal_code';

export default {
    components: {
        BFormGroup,
        BFormInput,
        BRow,
        BCol,
        BInputGroup,
        ValidationProvider,
    },

    props: {
        address: {
            type: Object,
            required: true,
        },
    },

    methods: {
        async searchAddressByPostalCode(postalCode) {
            const postalCodeWithoutSymbols = postalCode.replace(/[^a-zA-Z0-9]/g, '');
            const { data } = await PostalCodeService.getPostalCode(postalCodeWithoutSymbols);
            const {
                state, city, address, district,
            } = data;
            this.address.state = state;
            this.address.city = city;
            this.address.address = address;
            this.address.district = district;
        },
    },
};
</script>
