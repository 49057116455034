export default [
    'Academia e ginástica',
    'Agropecuária',
    'Alimentar',
    'Artigos Esportivos',
    'Associação e ONGs',
    'Bens e Consumo',
    'Borracha',
    'Com. de Animais e Acessórios',
    'Comércio Varejista',
    'Comunicação, Informática',
    'Construção Civil',
    'Cosméticos',
    'Editorial, Gráfica e Anúncios',
    'Ensino',
    'Farmacêutico e Equip. Hospitalares',
    'Financeiro / Crédito',
    'Geral',
    'Higiene e Limpeza',
    'Imobiliário',
    'Insumos agrícolas',
    'Máquinas e Equipamentos',
    'Material de Construção (Elétrico)',
    'Meios de Pagamento',
    'Mercadista',
    'Metalúrgica',
    'Móveis, acabamentos, esquadrias',
    'Papel / Papelão',
    'Peças e Acessórios Automotivos',
    'Plástico',
    'Profissional Liberal',
    'Química e Petroquímica',
    'Serviços',
    'Têxtil',
    'Transportes',
    'Vestuários',
];
