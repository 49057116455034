<template>
  <b-row>

    <b-col
      lg="4"
      md="6"
    >
      <b-form-group
        label-for="sector"
        label="Tipo do Lead"
      >
        <validation-provider
          #default="{ errors }"
          name="Tipo do Lead"
          rules="required"
        >
          <b-form-select
            id="sector"
            v-model="registration.record_type"
            :state="errors.length > 0 ? false : null"
            :options="[
              { text: 'Selecione um Tipo do Lead', value: null},
              'Crédito',
              'Hibrido',
              'Sacado',
              'SoulPay'
            ]"
          />
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
      </b-form-group>
    </b-col>

    <b-col
      lg="4"
      md="6"
    >
      <b-form-group
        label-for="sector"
        label="Tipo da Conta"
      >
        <validation-provider
          #default="{ errors }"
          name="Tipo da Conta"
          rules="required"
        >
          <b-form-select
            id="sector"
            v-model="registration.conversion_type"
            :state="errors.length > 0 ? false : null"
            :options="[
              { text: 'Selecione um Tipo de Conta', value: null},
              { value: 'CEDENTE', text: 'Cedente'},
              { value: 'CONTA SOULPAY', text: 'Conta SoulPay'},
              { value: 'HIBRIDO', text: 'Hibrido'},
              { value: 'SACADO', text: 'Sacado'},
            ]"
          />
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
      </b-form-group>
    </b-col>
    <b-col
      lg="4"
      md="6"
    >
      <b-form-group
        label-for="products"
        label="Produtos"
      >
        <multiselect
          v-model="registration.products"
          :options="productsOptions"
          :multiple="true"
          :close-on-select="false"
          :clear-on-select="false"
          :preserve-search="true"
          placeholder="Selecione os Produtos"
          select-label=""
          selected-label=""
          deselect-label=""
          label="product"
          track-by="product"
        >

          <span slot="noResult">
            Nenhum Produto Encontrado!
          </span>
        </multiselect>
      </b-form-group>
    </b-col>

    <b-col
      v-if="hasPaymentProduct"
      lg="4"
      md="6"
    >
      <validation-provider
        #default="{ errors }"
        name="Tipo da Conta"
        rules="rentability-simulator"
      >
        <b-form-group
          label-for="rentability-simulator"
          label="Simulador de Rentabilidade"
        >
          <b-form-file
            v-model="rentabilitySimulator"
            :state="errors.length > 0 ? false : null"
            placeholder="Arraste ou selecione um arquivo"
            drop-placeholder="Arraste o arquivo aqui."
          />
        </b-form-group>
        <small class="text-danger">{{ errors[0] }}</small>
      </validation-provider>
    </b-col>
  </b-row>
</template>

<script>
import {
    BFormGroup, BRow, BCol, BFormSelect, BFormFile,
} from 'bootstrap-vue';

import {
    ValidationProvider,
} from 'vee-validate';

import Multiselect from 'vue-multiselect';

import OnlineRegistration from '@/service/online_registration';

export default {
    components: {
        BFormGroup, BRow, BCol, ValidationProvider, Multiselect, BFormSelect, BFormFile,
    },

    props: {
        registration: {
            required: true,
            type: Object,
        },

        hasPaymentProduct: {
            default: () => false,
            type: Boolean,
        },
    },

    data() {
        return {
            products: [],
            rentabilitySimulator: null,
        };
    },

    computed: {
        productsOptions() {
            const options = this.products.map(({ product, id }) => ({
                product,
                id,
            }));

            return options;
        },
    },

    watch: {
        rentabilitySimulator(value) { this.$emit('import-rentability', value); },
    },

    mounted() {
        this.getAllProducts();
    },

    methods: {
        async getAllProducts() {
            const { data } = await OnlineRegistration.getProducts();

            this.products = data.data;
        },
    },
};
</script>
