/* eslint-disable max-classes-per-file */
const { default: Ramses } = require('../base/ramses');

class ComercialAgentsRequest extends Ramses {
    getAllAgents() {
        return this.fetch({
            url: 'comercial-agents',
        });
    }
}

export default new ComercialAgentsRequest();
