var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-row',[_c('b-col',{attrs:{"lg":"4","md":"6"}},[_c('b-form-group',{attrs:{"label-for":"sector","label":"Tipo do Lead"}},[_c('validation-provider',{attrs:{"name":"Tipo do Lead","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-select',{attrs:{"id":"sector","state":errors.length > 0 ? false : null,"options":[
            { text: 'Selecione um Tipo do Lead', value: null},
            'Crédito',
            'Hibrido',
            'Sacado',
            'SoulPay'
          ]},model:{value:(_vm.registration.record_type),callback:function ($$v) {_vm.$set(_vm.registration, "record_type", $$v)},expression:"registration.record_type"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"lg":"4","md":"6"}},[_c('b-form-group',{attrs:{"label-for":"sector","label":"Tipo da Conta"}},[_c('validation-provider',{attrs:{"name":"Tipo da Conta","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var errors = ref.errors;
return [_c('b-form-select',{attrs:{"id":"sector","state":errors.length > 0 ? false : null,"options":[
            { text: 'Selecione um Tipo de Conta', value: null},
            { value: 'CEDENTE', text: 'Cedente'},
            { value: 'CONTA SOULPAY', text: 'Conta SoulPay'},
            { value: 'HIBRIDO', text: 'Hibrido'},
            { value: 'SACADO', text: 'Sacado'} ]},model:{value:(_vm.registration.conversion_type),callback:function ($$v) {_vm.$set(_vm.registration, "conversion_type", $$v)},expression:"registration.conversion_type"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"lg":"4","md":"6"}},[_c('b-form-group',{attrs:{"label-for":"products","label":"Produtos"}},[_c('multiselect',{attrs:{"options":_vm.productsOptions,"multiple":true,"close-on-select":false,"clear-on-select":false,"preserve-search":true,"placeholder":"Selecione os Produtos","select-label":"","selected-label":"","deselect-label":"","label":"product","track-by":"product"},model:{value:(_vm.registration.products),callback:function ($$v) {_vm.$set(_vm.registration, "products", $$v)},expression:"registration.products"}},[_c('span',{attrs:{"slot":"noResult"},slot:"noResult"},[_vm._v(" Nenhum Produto Encontrado! ")])])],1)],1),(_vm.hasPaymentProduct)?_c('b-col',{attrs:{"lg":"4","md":"6"}},[_c('validation-provider',{attrs:{"name":"Tipo da Conta","rules":"rentability-simulator"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label-for":"rentability-simulator","label":"Simulador de Rentabilidade"}},[_c('b-form-file',{attrs:{"state":errors.length > 0 ? false : null,"placeholder":"Arraste ou selecione um arquivo","drop-placeholder":"Arraste o arquivo aqui."},model:{value:(_vm.rentabilitySimulator),callback:function ($$v) {_vm.rentabilitySimulator=$$v},expression:"rentabilitySimulator"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,3427345033)})],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }